<form (submit)="submit()" [formGroup]="w2form" novalidate>

  <div class="main pt-2">
    <div class="d-flex justify-content-between px-3 py-1">
      <h3>{{ isEdit ? ('Taxpayer.update' | translate) : ('Taxpayer.add' | translate) }} W2</h3>
      <div class="">
        <button (click)="hide()"
            aria-label="Close"
            class="close pull-right"
            tabindex="0"
            type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-content">
      <div class="row">
        <div class="col-12">
          <div class="card card-body mb-12">
            <div class="mb-2">
              <div class="row py-2">
                <div class="col-6">
                  <div class="form-group">
                    <label>{{ 'Taxpayer.type' | translate }} <span class="text-danger pl-1">*</span></label>
                    <div class="row pl-4">
                      <div class="col-sm-6 col-md-4 col-12">
                        <input [attr.aria-invalid]="w2form.get('type').invalid && (w2form.get('type')?.dirty || w2form.get('type')?.touched) ? 'true' : 'false'"
                            [class.is-invalid]="w2form.get('type').invalid && (w2form.get('type')?.dirty || w2form.get('type')?.touched)"
                            class="custom-control-input"
                            formControlName="type"
                            id="taxpayer"
                            name="type"
                            type="radio"
                            value="T">
                        <label class="custom-control-label ml-2" for="taxpayer">
                          {{ 'Taxpayer.taxpayer' | translate }}
                        </label>
                      </div>
                      <div *ngIf="individualReturn?.filing !== 'S'" class="col-sm-6 col-md-4 col-12 ml-2 ml-sm-0">
                        <input [attr.aria-invalid]="w2form.get('type').invalid && (w2form.get('type')?.dirty || w2form.get('type')?.touched) ? 'true' : 'false'"
                            [class.is-invalid]="w2form.get('type').invalid && (w2form.get('type')?.dirty || w2form.get('type')?.touched)"
                            class="custom-control-input"
                            formControlName="type"
                            id="spouse"
                            name="type"
                            type="radio"
                            value="S">
                        <label class="custom-control-label" for="spouse">
                          {{ 'Taxpayer.spouse' | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div *ngIf="this.individualReturn.resident && !excludedCities.includes(city?.name)" class="row">
                    <div class="col-12">
                      <div class="form-check form-check-inline">
                        <input (change)="disableOthers($event,'refundRequestedFlag')" class="form-check-input"
                            formControlName="refundRequestedFlag"
                            id="refundRequestedFlag" type="checkbox"/>
                        <label class="form-check-label" for="refundRequestedFlag">
                          {{ 'Taxpayer.refund-requested-other-city' | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!this.individualReturn.resident" class="row">
                    <div class="col-12">
                      <div class="form-check form-check-inline">
                        <input (change)="disableOthers($event,'refundRequestedFlag')" class="form-check-input"
                            formControlName="refundRequestedFlag"
                            id="refundRequestedFlagNoResident" type="checkbox"/>
                        <label class="form-check-label" for="refundRequestedFlagNoResident">
                          {{ 'Taxpayer.non-resident-refund-requested' | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="w2form.get('refundRequestedFlag').value === true" class="row d-flex inline-block">
                    <div *ngIf="this.individualReturn.resident" class="col-12">
                      {{ 'Taxpayer.upload-other-city-return' | translate }} <span class="text-danger pl-1">*</span>
                      <div *ngIf="!(isDisabled$|async)" class="upload-layout">
                        <div class="drop_box">
                          <header>
                            <h3 class="mb-0">{{ 'Taxpayer.select-file-here' | translate }}</h3>
                          </header>
                          <div class="text-center button-upload mt-2">
                            <button [attr.aria-busy]="(isUploading$ | async) ? 'true' : 'false'"
                                [attr.aria-label]="'Taxpayer.upload-file' | translate"
                                [disabled]="(isUploading$ | async)"
                                class="btn btn-primary font-weight-600 btn-upload"
                                tabindex="0">
                              <ui-spinner [class.active]="(isUploading$ | async) === 'City-Other'">{{ 'Taxpayer.upload-file' | translate }}</ui-spinner>
                            </button>

                            <input (change)="uploadDocument('City-Other', $event)"
                                [attr.aria-disabled]="(isDisabled$ | async) ? 'true' : 'false'"
                                [attr.aria-label]="'Taxpayer.file-upload-input-city-return' | translate"
                                [disabled]="(isDisabled$|async)"
                                accept=".pdf"
                                class="py-2 px-2 file-upload w-100 border bg-white drop-file"
                                name="Other City Return"
                                type="file"
                            />
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let file of individualReturn?.files" class="row">
                        <div *ngIf="file.type === 'City-Other'" class="col-12">
                          <div class="row no-gutters align-items-center border-top bg-primary-100 py-1">
                            <div class="col-8 align-items-center px-3 py-2 d-flex">
                              <svg *ngIf="file?.mimeType === 'application/pdf'" class="icon" viewBox="0 0 24 24">
                                <path d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z" stroke="none"/>
                              </svg>
                              <svg *ngIf="file?.mimeType === 'image/png' || file?.mimeType === 'image/jpg' || file?.mimeType === 'image/jpeg'" class="icon" viewBox="0 0 24 24">
                                <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z" stroke="none"/>
                              </svg>
                              {{ file?.fileName }}
                            </div>
                            <div class="ml-auto d-flex align-items-center pr-3">
                              <div class="text-nowrap mr-2">
                                <strong class="font-weight-medium">{{ file?.size * 1e-6 | number }}MB</strong>
                              </div>
                              <div class="d-flex align-items-center justify-content-center">
                                <button
                                    (click)="delete(file)"
                                    *ngIf="!(isDisabled$|async)"
                                    [attr.aria-label]="'Taxpayer.delete-file' | translate"
                                    class="btn btn-sm bg-danger border shadow p-1"
                                    container="body"
                                    placement="left"
                                    role="button"
                                    tabindex="0"
                                    tooltip="Delete"
                                    type="button"
                                >
                                  <svg class="icon icon-sm fill-white d-flex" viewBox="0 0 24 24">
                                    <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" stroke="none"/>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="!this.individualReturn.resident" class="col-12">
                      {{ 'Taxpayer.employer-wage-allocation-documentation' | translate }} <span class="text-danger pl-1">*</span>
                      <div *ngIf="!(isDisabled$|async)" class="upload-layout">
                        <div class="drop_box">
                          <header>
                            <h3 class="mb-0">{{ 'Taxpayer.select-file-here' | translate }}</h3>
                          </header>
                          <div class="text-center button-upload mt-2">
                            <button [attr.aria-busy]="(isUploading$ | async) ? 'true' : 'false'"
                                [attr.aria-disabled]="(isUploading$ | async) ? 'true' : 'false'"
                                [attr.aria-label]="'Taxpayer.upload-file' | translate"
                                [disabled]="(isUploading$ | async)"
                                class="btn btn-primary font-weight-600 btn-upload"
                                role="button"
                                tabindex="0">
                              <ui-spinner [class.active]="(isUploading$ | async) === 'Wage-Other'">{{ 'Taxpayer.upload-file' | translate }}</ui-spinner>
                            </button>

                            <input (change)="uploadDocument('Wage-Other', $event)"
                                [attr.aria-disabled]="(isDisabled$ | async) ? 'true' : 'false'"
                                [attr.aria-label]="'Taxpayer.select-file-to-upload' | translate"
                                [disabled]="(isDisabled$|async)"
                                accept=".pdf"
                                class="py-2 px-2 file-upload w-100 border bg-white drop-file"
                                name="Employer Wage Allocation Documentation"
                                type="file"
                            />
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let file of individualReturn?.files" class="row">
                        <div *ngIf="file.type === 'Wage-Other'" class="col-12">
                          <div class="row no-gutters align-items-center border-top bg-primary-100 py-1">
                            <div class="col-8 align-items-center px-3 py-2 d-flex">
                              <svg *ngIf="file?.mimeType === 'application/pdf'" class="icon" viewBox="0 0 24 24">
                                <path d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z" stroke="none"/>
                              </svg>
                              <svg *ngIf="file?.mimeType === 'image/png' || file?.mimeType === 'image/jpg' || file?.mimeType === 'image/jpeg'" class="icon" viewBox="0 0 24 24">
                                <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z" stroke="none"/>
                              </svg>
                              {{ file?.fileName }}
                            </div>
                            <div class="ml-auto d-flex align-items-center pr-3">
                              <div class="text-nowrap mr-2">
                                <strong class="font-weight-medium">{{ file?.size * 1e-6 | number }}MB</strong>
                              </div>
                              <div class="d-flex align-items-center justify-content-center">
                                <button
                                    (click)="delete(file)"
                                    *ngIf="!(isDisabled$|async)"
                                    [attr.aria-label]="'Taxpayer.delete-file' | translate"
                                    class="btn btn-sm bg-danger border shadow p-1"
                                    container="body"
                                    placement="left"
                                    role="button"
                                    tabindex="0"
                                    tooltip="Delete"
                                    type="button"
                                >
                                  <svg class="icon icon-sm fill-white d-flex" viewBox="0 0 24 24">
                                    <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" stroke="none"/>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col-6 d-flex inline-block">
                  <div class="form-check form-check-inline ">
                    <input (change)="disableOthers($event,'militaryWagesFlag')" class="form-check-input"
                        formControlName="militaryWagesFlag"
                        id="militaryWagesFlag" type="checkbox"/>
                    <label class="form-check-label" for="militaryWagesFlag">
                      {{ 'Taxpayer.active-duty-military-Wages' | translate }}
                    </label>
                  </div>
                </div>
                <div class="col-6 d-flex inline-block">
                  <div class="form-check form-check-inline ">
                    <input (change)="disableOthers($event,'under18WagesFlag')" class="form-check-input"
                        formControlName="under18WagesFlag"
                        id="under18WagesFlag" type="checkbox"/>
                    <label class="form-check-label" for="under18WagesFlag">
                      {{ 'Taxpayer.under-wages' | translate:{age: (city.name === 'Alliance' ? 16 : 18)} }}
                    </label>
                  </div>
                </div>
                <div class="col-6 d-flex inline-block">
                  <div class="form-check form-check-inline ">
                    <input (change)="disableOthers($event,'deferredCompensationFlag')" class="form-check-input"
                        formControlName="deferredCompensationFlag"
                        id="deferredCompensationFlag" type="checkbox"/>
                    <label class="form-check-label" for="deferredCompensationFlag">
                      {{ 'Taxpayer.deferred-compensation' | translate }} </label>
                  </div>
                </div>
              </div>
              <div *ngIf="showExcludedFlag() || w2form.get('under18WagesFlag').value" class="row py-2">
                <div class="col-6 d-flex inline-block">
                  <div *ngIf="showExcludedFlag()" class="form-check form-check-inline">
                    <input (change)="disableOthers($event,'excludedWagesFlag')" class="form-check-input" formControlName="excludedWagesFlag" id="excludedWagesFlag"
                        type="checkbox"
                    />
                    <label class="form-check-label" for="excludedWagesFlag">{{ 'Taxpayer.exclude-wage' | translate }}
                    </label>
                  </div>
                </div>
                <div class="col-6 d-flex inline-block">
                  <div *ngIf="w2form.get('under18WagesFlag').value" class="form-group">
                    <label class="" for="birthdate">{{ 'Taxpayer.date-birth' | translate }}<span
                        class="text-danger">*</span></label>
                    <input
                        [attr.aria-invalid]="w2form.get('birthdate')?.invalid && (w2form.get('birthdate')?.dirty || w2form.get('birthdate')?.touched) ? 'true' : 'false'"
                        [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                        [class.is-invalid]="w2form.get('birthdate')?.invalid && (w2form.get('birthdate')?.dirty || w2form.get('birthdate')?.touched)"
                        bsDatepicker
                        class="form-control"
                        formControlName="birthdate"
                        id="birthdate"
                        type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="employersName">{{ 'Taxpayer.employer-name' | translate }} <span class="text-danger pl-1">*</span></label>
                    <input [attr.aria-invalid]="w2form.get('employersName')?.invalid && (w2form.get('employersName')?.dirty || w2form.get('employersName')?.touched) ? 'true' : 'false'"
                        [class.is-invalid]="w2form.get('employersName')?.invalid && (w2form.get('employersName')?.dirty || w2form.get('employersName')?.touched)"
                        class="form-control"
                        formControlName="employersName"
                        id="employersName"
                        type="text">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="employersFederalId">{{ 'Taxpayer.fid' | translate }} <span class="text-danger pl-1">*</span></label>
                    <input [attr.aria-invalid]="w2form.get('employersFederalId')?.invalid && (w2form.get('employersFederalId')?.dirty || w2form.get('employersFederalId')?.touched) ? 'true' : 'false'"
                        [class.is-invalid]="w2form.get('employersFederalId')?.invalid && (w2form.get('employersFederalId')?.dirty || w2form.get('employersFederalId')?.touched)"
                        class="form-control"
                        formControlName="employersFederalId"
                        id="employersFederalId"
                        mask="00-0000000"
                        placeholder="00-0000000"
                        type="text">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="box1Wages">{{ 'Taxpayer.box1-wages-and-tips' | translate }} <span class="text-danger pl-1">*</span></label>
                    <input
                        [attr.aria-invalid]="w2form.get('box1Wages')?.invalid && (w2form.get('box1Wages')?.dirty || w2form.get('box1Wages')?.touched) ? 'true' : 'false'"
                        [class.is-invalid]="w2form.get('box1Wages')?.invalid && (w2form.get('box1Wages')?.dirty || w2form.get('box1Wages')?.touched)"
                        [decimals]="city.components.individual.fractionDigits"
                        class="w-100 form-control text-right custom-height"
                        formControlName="box1Wages"
                        id="box1Wages"
                        name="box1Wages"
                        type="text"
                        ui-number
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="totalBox5Wages">{{ 'Taxpayer.box5-medicare-wages-and-tips' | translate }} <span class="text-danger pl-1">*</span></label>
                    <input
                        [attr.aria-invalid]="w2form.get('totalBox5Wages')?.invalid && (w2form.get('totalBox5Wages')?.dirty || w2form.get('totalBox5Wages')?.touched) ? 'true' : 'false'"
                        [class.is-invalid]="w2form.get('totalBox5Wages')?.invalid && (w2form.get('totalBox5Wages')?.dirty || w2form.get('totalBox5Wages')?.touched)"
                        [decimals]="city.components.individual.fractionDigits"
                        class="w-100 form-control text-right custom-height"
                        formControlName="totalBox5Wages"
                        id="totalBox5Wages"
                        name="totalBox5Wages"
                        type="text"
                        ui-number
                    >
                  </div>
                </div>
              </div>

              <div *ngIf="!w2form.get('excludedWagesFlag').value" class="row justify-content-end">
                <div class="col-12 text-sm font-weight-bold pb-1 text-uppercase">{{ 'Taxpayer.fill-dates-not-work-all-year' | translate }}
                </div>
                <div class="col-6">
                  <div class="form-group" id="dateWorkedFrom">
                    <label for="dateWorkedFromInput">{{ 'Taxpayer.worked-from' | translate }}</label>
                    <input [attr.aria-invalid]="w2form.get('dateWorkedFrom')?.invalid && (w2form.get('dateWorkedFrom')?.dirty || w2form.get('dateWorkedFrom')?.touched) ? 'true' : 'false'"
                        [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                        [class.is-invalid]="w2form.get('dateWorkedFrom')?.invalid && (w2form.get('dateWorkedFrom')?.dirty || w2form.get('dateWorkedFrom')?.touched)"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        bsDatepicker
                        class="form-control"
                        formControlName="dateWorkedFrom"
                        id="dateWorkedFromInput"
                        type="text"
                    >
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group" id="dateWorkedTo">
                    <label for="dateWorkedToInput">{{ 'Taxpayer.worked-to' | translate }}</label>
                    <input [attr.aria-invalid]="w2form.get('dateWorkedTo')?.invalid && (w2form.get('dateWorkedTo')?.dirty || w2form.get('dateWorkedTo')?.touched) ? 'true' : 'false'"
                        [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                        [class.is-invalid]="w2form.get('dateWorkedTo')?.invalid && (w2form.get('dateWorkedTo')?.dirty || w2form.get('dateWorkedTo')?.touched)"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        bsDatepicker
                        class="form-control"
                        formControlName="dateWorkedTo"
                        id="dateWorkedToInput"
                        type="text"
                    >
                  </div>
                </div>
              </div>

              <div [class.is-invalid]="w2form.get('w2LocalityInfo')?.invalid && (w2form.get('w2LocalityInfo')?.dirty || w2form.get('w2LocalityInfo')?.touched)"
                  class="row bg-light border">
                <div class="col-11 py-2 font-weight-bold">{{ 'Taxpayer.w2-locality-info' | translate }} <span class="text-danger pl-1">*</span></div>
                <div class="col-1 d-flex justify-content-end align-items-center">
                  <button (click)="addW2LocalityInfo()"
                      *ngIf="!(isDisabled$|async)"
                      [attr.aria-busy]="(allowLocality$ | async) ? 'true' : 'false'"
                      [attr.aria-disabled]="(allowLocality$ | async) ? 'true' : 'false'"
                      [attr.aria-label]="'Taxpayer.add' | translate"
                      [disabled]="allowLocality$|async"
                      class="btn btn-primary font-weight-600 ml-3 py-1"
                      role="button"
                      tabindex="0"
                      type="button">
                    {{ 'Taxpayer.add' | translate }}
                  </button>
                </div>
              </div>
              <ng-container *ngIf="w2LocalityInfo?.controls?.length" formGroupName="w2LocalityInfo">
                <div *ngIf="!w2form.get('refundRequestedFlag').value || !this.individualReturn.resident" class="row pt-2">
                  <div class="col-11">
                    <div class="row d-flex align-items-end">
                      <label class="col-3 font-weight-medium">{{ 'Taxpayer.locality' | translate }}<span class="text-danger">*</span></label>
                      <label class="col-3 font-weight-medium">{{ 'Taxpayer.box18-local-wages' | translate }}</label>
                      <label class="col-3 font-weight-medium">{{ 'Taxpayer.box19-local-tax' | translate }}</label>
                      <label class="col-3 font-weight-medium">{{ 'Taxpayer.credit-for-tax-withheld' | translate }}<br>
                        <small>{{ 'Taxpayer.city-other-than-resident-city' | translate }}</small>
                      </label>
                    </div>
                  </div>
                  <div class="col-auto">
                  </div>
                </div>

                <div *ngIf="w2form.get('refundRequestedFlag').value && this.individualReturn.resident" class="row pt-2">
                  <div class="col-11">
                    <div class="row d-flex align-items-end">
                      <label class="col-3 font-weight-medium">{{ 'Taxpayer.locality' | translate }}<span class="text-danger">*</span></label>
                      <label class="col-3 font-weight-medium">{{ 'Taxpayer.box18-local-wages' | translate }}</label>
                      <label class="col-2 font-weight-medium">{{ 'Taxpayer.box19-local-tax' | translate }}</label>
                      <label class="col-2 font-weight-medium">{{ 'Taxpayer.refund-requested' | translate }}</label>
                      <label class="col-2 font-weight-medium">{{ 'Taxpayer.credit-for-tax-withheld' | translate }}</label>
                    </div>
                  </div>
                  <div class="col-auto">
                  </div>
                </div>

                <ng-container *ngFor="let locality of w2LocalityInfo?.controls; index as i">
                  <div [formGroupName]="i" class="row pb-1">

                    <div *ngIf="!w2form.get('refundRequestedFlag').value || !this.individualReturn.resident" class="col-11">
                      <city-tax-feature-locality
                          [residency]="this.individualReturn.residency"
                          [ownProperty]="this.individualReturn.ownProperty"
                          [count]="w2LocalityInfo?.controls.length"
                          [dateWorkedFrom]="w2form.get('dateWorkedFrom').value"
                          [dateWorkedTo]="w2form.get('dateWorkedTo').value"
                          [localityFormGroup]="locality"
                          [locality]="locality.value"
                          [multipleLocations]="w2form.get('multipleLocations').value"

                          [type]="w2form.get('type').value"
                      >
                      </city-tax-feature-locality>
                    </div>

                    <div *ngIf="w2form.get('refundRequestedFlag').value && this.individualReturn.resident" class="col-11">
                      <city-tax-feature-locality-refund
                          [count]="w2LocalityInfo?.controls.length"
                          [dateWorkedFrom]="w2form.get('dateWorkedFrom').value"
                          [dateWorkedTo]="w2form.get('dateWorkedTo').value"
                          [localityFormGroup]="locality"
                          [locality]="locality.value"
                          [multipleLocations]="w2form.get('multipleLocations').value"
                          [type]="w2form.get('type').value"
                      >
                      </city-tax-feature-locality-refund>
                    </div>

                    <div class="col-auto d-flex align-items-center">
                      <div (click)="removeW2LocalityInfo(locality)" *ngIf="!(isDisabled$|async)"
                          class="form-group cursor-pointer">
                        <svg class="icon icon-sm fill-danger-500" viewBox="0 0 24 24">
                          <path
                              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                              stroke="none"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div *ngIf="individualReturn.resident==true && !w2form.get('multipleLocations').value && w2LocalityInfo?.controls.length > 1"
                    class="">
                  <p class="fs-11px mb-2  text-danger">
                    {{ 'Taxpayer.tax-withheld-message' | translate: {cityName: city.name} }}
                  </p>
                </div>
                <div *ngIf="individualReturn.residency==='P'" class="">
                  <p class="fs-11px mb-2 text-danger">{{ 'Taxpayer.non-resident-wages' | translate }}</p>
                </div>
                <p>{{ 'Taxpayer.non-taxing-jurisdiction' | translate }}</p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <a (click)="hide()"
        [attr.aria-label]="'Buttons.cancel-btn' | translate"
        class="btn btn-link"
        href="javascript:"
        role="button"
        tabindex="0">
      {{ "Buttons.cancel-btn" | translate }}
    </a>
    <button *ngIf="!(isDisabled$|async)"
        [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
        [attr.aria-disabled]="(isDisabled$ | async) ? 'true' : 'false'"
        [attr.aria-label]="isEdit ? 'Update' : ('Buttons.submit-btn' | translate)"
        [disabled]="(isLoading$ | async)"
        class="btn btn-primary f-bold"
        role="button"
        tabindex="0"
        type="submit"
    >
      <ui-spinner [class.active]="isLoading$ | async">
        {{ isEdit ? ("Taxpayer.update" | translate) : ("Buttons.submit-btn" | translate) }}
      </ui-spinner>
    </button>
  </div>
</form>
